import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './Eyebrow.module.scss'

const Eyebrow = ({ element: Element = 'p', children, className, ...props }) => {
  const classes = classNames(styles.Eyebrow, className)

  return (
    <Element className={classes} data-qa="eyebrow" {...props}>
      {children}
    </Element>
  )
}

Eyebrow.propTypes = {
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  children: PropTypes.node,
  className: PropTypes.string,
}

export { Eyebrow }
export default Eyebrow
