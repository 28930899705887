import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import normalizePagePath from '../utilities/normalizePagePath'

const query = graphql`
  query PageMetaData {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
        locales {
          all
        }
      }
    }
  }
`

/**
 * This function takes an array of raw robots instructions and returns
 * a new array of normalized values to be fed into the meta tag.
 */
const normalizeRobots = robots => {
  const settings = robots && robots.length > 0 ? robots : []
  return settings.filter(x => ['noindex', 'nofollow'].indexOf(x) !== -1)
}

const PageMeta = ({
  keywords,
  language,
  title,
  description,
  image,
  pathname,
  canonicalUrlOverride,
  article,
  robots,
}) => {
  const {
    site: {
      siteMetadata: {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
        twitterUsername,
        locales,
      },
    },
  } = useStaticQuery(query)

  const seo = {
    lang: language || locales.default,
    title: title || defaultTitle,
    keywords: keywords || '',
    description: description || defaultDescription,
    image: image ? image.url : `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${normalizePagePath(pathname, {
      locale: language || locales.default,
      defaultLocale: locales.default,
    })}`,
  }

  const robotsSettings = normalizeRobots(robots)

  return (
    <Helmet
      title={seo.title}
      titleTemplate={
        seo.title === 'Elevations Credit Union' ? null : `%s | Elevations Credit Union`
      }
    >
      <html lang={seo.lang} />

      {/* Q2 defense against credential stuffing attacks */}
      <script type="text/javascript" src="https://cds-sdkcfg.onlineaccess1.com/common.js" />

      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="preconnect" href="https://lptag.liveperson.net" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
      <link rel="preconnect" href="https://www.googleadservices.com" />
      <link rel="preconnect" href="https://www.google.com" />

      {locales.all
        .filter(locale => locale !== seo.lang)
        .map(locale => {
          return (
            <link
              key={`key-${locale}`}
              rel="alternate"
              hrefLang={locale}
              href={`${siteUrl}${normalizePagePath(pathname, {
                locale,
                defaultLocale: locales.default,
              })}`}
            />
          )
        })}

      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />

      {/* TODO: This was disabled due to EGS-109 as it was breaking Site Search.
          It should be safe to re-enable but keeping disabled for now.
          Furthermore, this was unintentionally tested when we pushed out Spanish.
          It apparently works well with Site search after we fixed EGS-109 as long as the URL is correct. */}
      {canonicalUrlOverride && <link rel="canonical" href={canonicalUrlOverride} />}

      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {robotsSettings.length > 0 && <meta name="robots" content={robotsSettings.join(',')} />}

      <meta name="image" content={seo.image} />
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}

      {/* Global Custom Fields for Elastic Site Search */}
      {/* https://swiftype.com/documentation/site-search/crawler-configuration/meta-tags */}
      <meta className="swiftype" name="title" data-type="string" content={seo.title} />
      <meta className="swiftype" name="site-priority" data-type="integer" content={2} />
      <meta className="swiftype" name="popularity" data-type="integer" content={1} />
      <meta className="swiftype" name="locale" data-type="enum" content={seo.lang} />
      <meta className="swiftype" name="description" data-type="string" content={seo.description} />
      {seo.keywords && (
        <meta className="swiftype" name="keywords" data-type="string" content={seo.keywords} />
      )}

      {/* Commented out the below line as it was causing thumbnails in facebook to preview a 404  */}
      {/* {seo.url && <meta property="og:url" content={seo.url} />} */}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {/* Google Sitelinks Searchbox can only appear on the homepage */}
      {/* https://developers.google.com/search/docs/data-types/sitelinks-searchbox */}
      {pathname === '/' && (
        <script type="application/ld+json">{`
                {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "url": "${siteUrl}",
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": "${siteUrl}?q={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                }
              `}</script>
      )}

      {/* LiveEngage */}
      <script>{`
                window.lpTag=window.lpTag||{},'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:'${process.env.LIVEENGAGE_SITE_ID}'||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);var o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger('LPT','DOM_READY',{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;
              `}</script>
      {/* Feedback button */}
      <script
        type="text/javascript"
        src="https://resources.digital-cloud-west.medallia.com/wdcwest/227785/onsite/embed.js"
        async
      />
      {/* Glia script */}
      <script async src="https://api.glia.com/salemove_integration.js" />
    </Helmet>
  )
}

PageMeta.propTypes = {
  keywords: PropTypes.string,
  language: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  robots: PropTypes.arrayOf(PropTypes.oneOf(['nofollow', 'noindex'])),
  canonicalUrlOverride: PropTypes.string,
}

PageMeta.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: '/',
  article: false,
  robots: [],
  canonicalUrlOverride: null,
}

export { PageMeta }
export default PageMeta
